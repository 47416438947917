import { ReactiveComponent } from '~/commons/component/ReactiveComponent'
import { Component, Prop, Watch } from '~/commons/component/decorators'

import { ajax } from '~/services/ajax'

import { omit, pick, isEmpty } from 'lodash-es'

import cooperationData from './etc/cooperation.json'

const SiteDivideType = {
  Ratio: 1,
  MonthlyAmount: 2,
  DailyAmount: 3,
  RatioOrMonthlyAmount: 4,
  RatioOrDailyAmount: 5,
  RatioOnDailyAmountThreshold: 6,
  RatioAndMonthlyAmount: 7,
  WeChatRealTimeAmount: 8
}

const CounselorDivideType = {
  ExcludeLandFeeLast: 1,
  ExcludeLandFeeFirst: 2,
  NoLandFee: 3,
  RatioOnDailyAmountThreshold: 4,
  WeChatRealTimeAmount: 5
}

const OperationDivideType = {
  Ratio: 1,
  MonthlyAmount: 2,
  ExcludeLandFee: 3,
}

@Component()
export
class Cooperation extends ReactiveComponent {
  type = {
    'supervisor.edit': 1,
    'network.edit': 2,
  }[this.$route.name]

  dialog = createDefaultDialog()

  siteOptions = []
  counselorOptions = []
  operationOptions = []
  companyOptions = []

  cooperationCreated = false

  loading = false
  committing = false

  isPercentOver30 = false

  mounted() {
    this.loadData()
  }

  loadData() {
    this.loading = true

    ajax(
      '/api/index_view',
      {
        method: 'POST',
        body: {
          type: this.type,
          supervisor_id: this.$route.params.id,
          network_id: this.$route.params.id,
        }
      },

      (error, data) => {
        this.loading = false

        if (error) {
          this.$message({ message: '获取数据失败', type: 'error' })
        } else {
          const { dialog, siteList, counselorList, operationList, company } = data

          if (isEmpty(dialog.site)) {
            dialog.site = this.dialog.site
          } else {
            this.cooperationCreated = true
          }

          if (isEmpty(dialog.counselor)) {
            dialog.counselor = this.dialog.counselor
          } else {
            this.cooperationCreated = true
          }

          if (isEmpty(dialog.operation)) {
            dialog.operation = this.dialog.operation
          } else {
            this.cooperationCreated = true
          }

          if (this.cooperationCreated) {
            dialog.counselor.push(createDefaultCounselor(pick(dialog.counselor[0], [ 'final_company' ])))
          }

          this.dialog = dialog
          this.siteOptions = siteList
          this.counselorOptions = counselorList
          this.operationOptions = operationList
          this.companyOptions = company
        }
      }
    )
  }

  // @Watch('dialog')
  // disableCounselorAdditionIfNeccessary() {
  //   if (!this.cooperationCreated) {
  //     this.dialog.counselor = this.dialog.counselor.filter(item => item.id > 0)
  //   }
  // }

  @Watch('dialog.site.divide_type')
  changeCounselorDivideType(){
    if(this.dialog.site.divide_type === SiteDivideType.WeChatRealTimeAmount){
      this.dialog.counselor.forEach(item => {
        item.divide_type = CounselorDivideType.WeChatRealTimeAmount
      })
    }
  }

  shouldDivideInRatioForSite(devideType) {
    return [ SiteDivideType.Ratio, SiteDivideType.RatioOrMonthlyAmount, SiteDivideType.RatioOrDailyAmount, SiteDivideType.RatioOnDailyAmountThreshold, SiteDivideType.RatioAndMonthlyAmount ].includes(devideType)
  }

  shouldDivideInMonthlyAmountForSite(devideType) {
    return [ SiteDivideType.MonthlyAmount, SiteDivideType.RatioOrMonthlyAmount, SiteDivideType.RatioAndMonthlyAmount ].includes(devideType)
  }

  shouldDivideInDailyAmountForSite(devideType) {
    return [ SiteDivideType.DailyAmount, SiteDivideType.RatioOrDailyAmount, SiteDivideType.RatioOnDailyAmountThreshold ].includes(devideType)
  }

  shouldDivideInWeChatRealTimeAmountForSite(devideType){
    return [ SiteDivideType.WeChatRealTimeAmount ].includes(devideType)
  }

  shouldDivideInRatioForOperation(devideType) {
    return [ OperationDivideType.Ratio, OperationDivideType.ExcludeLandFee ].includes(devideType)
  }

  shouldDivideInAmountForOperation(devideType) {
    return [ OperationDivideType.MonthlyAmount ].includes(devideType)
  }

  addCounselor(item) {
    item.adding = true
    this.dialog.counselor.push(createDefaultCounselor(pick(item, [ 'final_company' ])))
  }

  deleteCounselor(item) {
    const idx = this.dialog.counselor.indexOf(item)

    if (idx === -1) {
      return
    }

    this.dialog.counselor.splice(idx, 1)

    if (item.id === 0) {
      return
    }

    this.$confirm('确定要删除吗?', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning',
    }).then(
      () => {
        this.handleDeleteCounselor(item)
      },
      () => {},
    )
  }

  handleSubmit() {

    this.isPercentOver30 = this.dialog.counselor.reduce((acc, item) => acc + Number(item.percent), 0) > 30
    if(this.dialog.site.divide_type === SiteDivideType.WeChatRealTimeAmount && this.isPercentOver30) {
      return  this.$message({ message: '微信商户实习分账分成比例不能超过30%', type: 'warning' })
    }


    if (this.cooperationCreated) {
      this.handleSubmitUpdate()
    } else {
      this.handleSubmitAdd()
    }
  }

  handleSubmitAdd() {
    this.loading = true

    ajax(
      '/api/add_cooperation',
      {
        method: 'POST',
        body: {
          type: this.type,
          set_id: this.$route.params.id,
          site_data: this.dialog.site,
          // counselor_data: this.dialog.counselor.filter(item => item.id === 0 && item.adding),
          counselor_data: this.dialog.counselor.filter(item => item.id === 0 && item.adding),
          cooperation_data: this.dialog.operation,
        }
      },

      (error, data) => {
        this.loading = false

        if (error) {
          this.$message({ message: /*error.message ?? */'操作失败', type: 'error' })
        } else {
          this.$message({ message: '提交成功', type: 'success' })

          this.loadData()
        }

      }
    )
  }

  handleSubmitUpdate() {
    this.loading = true

    ajax(
      '/api/update_cooperation',
      {
        method: 'POST',
        body: {
          type: this.type,
          set_id: this.$route.params.id,
          site_data: this.dialog.site,
          counselor_data: this.dialog.counselor.filter(item => item.id > 0 || item.adding),
          cooperation_data: this.dialog.operation,
        }
      },

      (error, data) => {
        this.loading = false

        if (error) {
          this.$message({ message: /*error.message ?? */'操作失败', type: 'error' })
        } else {
          this.$message({ message: '提交成功', type: 'success' })

          this.loadData()
        }

      }
    )
  }

  handleDeleteCounselor(item) {
    ajax(
      '/api/delete_counselor',
      {
        method: 'POST',
        body: {
          id: item.id,
        }
      },

      (error, data) => {
        if (error) {
          this.$message({ message: error.message ?? '操作失败', type: 'error' })
        } else {
          this.$message({ message: '操作成功', type: 'success' })
        }

        this.loadData()
      }
    )
  }

  render() {
    return (
      <div class="box-tag-block supervisor-info" vLoading_body={ this.loading } vPermission={ 'cooperation.show' }>
        <div class="box-title"><span>场地合作</span></div>
        <div slot="body" class="box-body el-col-md-16">
          <el-form value={ this.dialog.site } onInput={ e => (this.dialog.site = e) }>
            {/*<el-row>
              <el-col xs={ 24 } md={ 16 }>*/}
                <el-form-item label="结算公司">
                  <el-select placeholder="请选择结算公司" value={ this.dialog.site.final_company || '' } onInput={ e => (this.dialog.site.final_company = e) } filterable clearable>
                    {
                      this.companyOptions.map(item => (
                        <el-option label={ item.value } value={ item.key } />
                      ))
                    }
                  </el-select>
                </el-form-item>
                <el-form-item label="场地合作单位">
                  <el-select placeholder="请选择场地合作单位" value={ this.dialog.site.cooperation_id || '' } onInput={ e => (this.dialog.site.cooperation_id = e) } filterable clearable>
                    {
                      this.siteOptions.map(item => (
                        <el-option label={ item.name } value={ item.id } />
                      ))
                    }
                  </el-select>
                </el-form-item>
                <el-form-item label="分成方式" style="width: 150%;">
                  <el-radio-group value={ this.dialog.site.divide_type } onInput={ e => (this.dialog.site.divide_type = e) } style="line-height: 40px;">
                    <el-radio label={ SiteDivideType.Ratio }>分成比例</el-radio>
                    <el-radio label={ SiteDivideType.MonthlyAmount }>每月固定金额</el-radio>
                    <el-radio label={ SiteDivideType.DailyAmount }>每天固定金额</el-radio>
                    <el-radio label={ SiteDivideType.RatioOrMonthlyAmount }>分成比例或每台每月固定两者取其高分成</el-radio>
                    <el-radio label={ SiteDivideType.RatioOrDailyAmount }>分成比例或每台每天固定两者取其高分成</el-radio>
                    <el-radio label={ SiteDivideType.RatioOnDailyAmountThreshold }>每台每天均收大于X元，按比例分成</el-radio>
                    <el-radio label={ SiteDivideType.RatioAndMonthlyAmount }>分成比例和每月固定金额</el-radio>
                    <el-radio label={ SiteDivideType.WeChatRealTimeAmount }>微信商户实时分账</el-radio>
                  </el-radio-group>
                </el-form-item>

                {
                  this.shouldDivideInRatioForSite(this.dialog.site.divide_type) &&
                  <el-form-item label="分成比例">
                    <el-input value={ this.dialog.site.percent } onInput={ e => (this.dialog.site.percent = e) }>
                      <span slot="append">%</span>
                    </el-input>
                  </el-form-item>
                }

                {
                  this.shouldDivideInMonthlyAmountForSite(this.dialog.site.divide_type) &&
                  <el-form-item label="每月固定金额">
                    <el-input value={ this.dialog.site.values } onInput={ e => (this.dialog.site.values = e) }>
                      <span slot="append">元/月</span>
                    </el-input>
                  </el-form-item>
                }

                {
                  this.shouldDivideInDailyAmountForSite(this.dialog.site.divide_type) &&
                  <el-form-item label="每天固定金额">
                    <el-input value={ this.dialog.site.values } onInput={ e => (this.dialog.site.values = e) }>
                      <span slot="append">元/天</span>
                    </el-input>
                  </el-form-item>
                }


                {
                  this.dialog.site.divide_type != 8  &&
                  <div>
                    <el-form-item label="服务费">
                      <el-input value={ this.dialog.site.service_charge } onInput={ e => (this.dialog.site.service_charge = e) }>
                        <span slot="append">%</span>
                      </el-input>
                    </el-form-item>

                    <el-form-item label="流量费">
                      <el-input value={ this.dialog.site.flow_charge } onInput={ e => (this.dialog.site.flow_charge = e) }>
                        <span slot="append">元/(台x月)</span>
                      </el-input>
                    </el-form-item>

                    <el-form-item label="设备数量">
                      <el-input value={ this.dialog.site.device_num } onInput={ e => (this.dialog.site.device_num = e) }>
                        <span slot="append">台</span>
                      </el-input>
                    </el-form-item>

                    <el-form-item label="备注">
                      <el-input value={ this.dialog.site.remark } onInput={ e => (this.dialog.site.remark = e) } />
                    </el-form-item>
                  </div>
                }
              {/*</el-col>
            </el-row>*/}
          </el-form>
        </div>

        <div class="box-title"><span>顾问合作</span></div>
        <div slot="body" class="box-body el-col-md-16">
          <el-form value={ this.dialog.counselor } onInput={ e => (this.dialog.counselor = e) }>
            {/*<el-row>
              <el-col xs={ 24 } md={ 16 }>*/}
                <el-form-item label="结算公司">
                  <el-select placeholder="请选择结算公司" value={ this.dialog.counselor[0]?.final_company || '' } onInput={ e => this.dialog.counselor.forEach(c => c.final_company = e) } filterable clearable>
                    {
                      this.companyOptions.map(item => (
                        <el-option label={ item.value } value={ item.key } />
                      ))
                    }
                  </el-select>
                </el-form-item>
                <el-form-item label="顾问限制分成比例">
                  <el-input value={ this.dialog.counselor[0]?.not_pay || '' } onInput={ e => this.dialog.counselor.forEach(c => c.not_pay = e) } clearable>
                    <span slot="append">%</span>
                  </el-input>
                  <span class="tips">(场地方分成超过此百份比顾问不分成, 不填则不限制)</span>
                </el-form-item>

                <el-form-item label="顾问单位" style="width: 150%;">
                  <el-table data={ this.dialog.counselor } stripe>
                    <el-table-column label="顾问单位名称" scopedSlots={
                      {
                        default: scope => (
                          <el-select placeholder="请选择顾问单位" value={ scope.row.cooperation_id || '' } onInput={ e => (scope.row.cooperation_id = e) } size="small" filterable clearable>
                            {
                              this.counselorOptions.map(item => (
                                <el-option label={ item.name } value={ item.id } />
                              ))
                            }
                          </el-select>
                        )
                      }
                    } />
                    <el-table-column label="分成方式" scopedSlots={
                      {
                        default: scope => (
                          <el-select placeholder="请选择分成方式" value={ scope.row.divide_type || '' } onInput={ e => (scope.row.divide_type = e) } size="small" filterable clearable disabled={this.dialog.site.divide_type === SiteDivideType.WeChatRealTimeAmount}>
                            <el-option label="营收按比例后扣除场地费分成" value={ CounselorDivideType.ExcludeLandFeeLast } />
                            <el-option label="营收扣除场地费后按比例分成" value={ CounselorDivideType.ExcludeLandFeeFirst } />
                            <el-option label="实际比例分成" value={ CounselorDivideType.NoLandFee } />
                            <el-option label="每台每天均收大于X元，按比例分成" value={ CounselorDivideType.RatioOnDailyAmountThreshold } />
                            <el-option label='微信商户实时分账' value={ CounselorDivideType.WeChatRealTimeAmount } />
                          </el-select>
                        )
                      }
                    }/>
                    <el-table-column label="分成比例/%" scopedSlots={
                      {
                        default: scope => <div style="display: flex;justify-content: center;align-items: center;">
                          {
                            this.isPercentOver30 &&
                            <i class='el-icon-warning' style="color: orange;margin-right: 5px;"/>
                          }
                          <el-input value={ scope.row.percent } onInput={ e => (scope.row.percent = e) } size="small" />
                        </div>
                      }
                    }/>
                    <el-table-column label="每台每天/元" scopedSlots={
                      {
                        default: scope => (
                          scope.row.divide_type === CounselorDivideType.RatioOnDailyAmountThreshold &&
                          <el-input value={ scope.row.values } onInput={ e => (scope.row.values = e) } size="small" />
                        )
                      }
                    }/>
                    <el-table-column label="服务费/%" scopedSlots={
                      {
                        default: scope => <el-input value={ scope.row.service_charge } onInput={ e => (scope.row.service_charge = e) } size="small" />
                      }
                    }/>
                    <el-table-column label="流量费/(元/(台x月))" scopedSlots={
                      {
                        default: scope => <el-input value={ scope.row.flow_charge } onInput={ e => (scope.row.flow_charge = e) } size="small" />
                      }
                    }/>
                    <el-table-column label="联系人" scopedSlots={
                      {
                        default: scope => this.counselorOptions.find(item => item.id === scope.row.cooperation_id)?.contacts
                      }
                    }/>
                    <el-table-column label="联系方式" scopedSlots={
                      {
                        default: scope => this.counselorOptions.find(item => item.id === scope.row.cooperation_id)?.mobile
                      }
                    }/>
                    <el-table-column label="操作" scopedSlots={
                      {
                        default: scope => (
                          scope.row.id === 0 && !scope.row.adding ?
                            <el-button type="primary" size="small" onClick={ () => this.addCounselor(scope.row) }>添加</el-button> :
                            <el-button type="danger" size="small" onClick={ () => this.deleteCounselor(scope.row) }>删除</el-button>
                        )
                      }
                    }/>
                  </el-table>

                  {
                    this.shouldDivideInWeChatRealTimeAmountForSite(this.dialog.site.divide_type) &&
                    <span  style="color: orange;">分成比例累加总额不能超过30%</span>
                  }
                </el-form-item>
              {/*</el-col>
            </el-row>*/}
          </el-form>
        </div>

        <div class="box-title"><span>运维合作</span></div>
        <div slot="body" class="box-body el-col-md-16">
          <el-form value={ this.dialog.operation } onInput={ e => (this.dialog.operation = e) }>
            {/*<el-row>
              <el-col xs={ 24 } md={ 16 }>*/}
                <el-form-item label="结算公司">
                  <el-select placeholder="请选择结算公司" value={ this.dialog.operation.final_company || '' } onInput={ e => (this.dialog.operation.final_company = e) } filterable clearable>
                    {
                      this.companyOptions.map(item => (
                        <el-option label={ item.value } value={ item.key } />
                      ))
                    }
                  </el-select>
                </el-form-item>
                <el-form-item label="运维合作单位">
                  <el-select placeholder="请选择运维合作单位" value={ this.dialog.operation.cooperation_id || '' } onInput={ e => (this.dialog.operation.cooperation_id = e) } filterable clearable>
                    {
                      this.operationOptions.map(item => (
                        <el-option label={ item.name } value={ item.id } />
                      ))
                    }
                  </el-select>
                </el-form-item>
                <el-form-item label="分成方式">
                  <el-radio-group value={ this.dialog.operation.divide_type } onInput={ e => (this.dialog.operation.divide_type = e) } style="line-height: 40px;">
                    <el-radio label={ OperationDivideType.Ratio }>分成比例</el-radio>
                    <el-radio label={ OperationDivideType.MonthlyAmount }>每月固定金额</el-radio>
                    <el-radio label={ OperationDivideType.ExcludeLandFee }>扣除场地费后分成</el-radio>
                  </el-radio-group>
                </el-form-item>

                {
                  this.shouldDivideInRatioForOperation(this.dialog.operation.divide_type) &&
                  <el-form-item label="分成比例">
                    <el-input value={ this.dialog.operation.percent } onInput={ e => (this.dialog.operation.percent = e) } disabled={ this.operationDisabled }>
                      <span slot="append">%</span>
                    </el-input>
                  </el-form-item>
                }

                {
                  this.shouldDivideInAmountForOperation(this.dialog.operation.divide_type) &&
                  <el-form-item label="每月固定金额">
                    <el-input value={ this.dialog.operation.values } onInput={ e => (this.dialog.operation.values = e) } disabled={ this.operationDisabled }>
                      <span slot="append">元/月</span>
                    </el-input>
                  </el-form-item>
                }

                <el-form-item label="服务费">
                  <el-input value={ this.dialog.operation.service_charge } onInput={ e => (this.dialog.operation.service_charge = e) }>
                    <span slot="append">%</span>
                  </el-input>
                </el-form-item>
                <el-form-item label="流量费">
                  <el-input value={ this.dialog.operation.flow_charge } onInput={ e => (this.dialog.operation.flow_charge = e) }>
                    <span slot="append">元/(台x月)</span>
                  </el-input>
                </el-form-item>

              {/*</el-col>
            </el-row>*/}
          </el-form>
        </div>

        <div slot="footer" class="box-footer">
          <el-button type="primary" loading={ this.committing } vPermission={ 'cooperation.update' } onClick={ () => this.handleSubmit() }>提交</el-button>
          <router-link to={ { name: 'supervisor' } }><el-button>取消</el-button></router-link>
          <tips />
        </div>
      </div>
    )
  }
}

function createDefaultDialog() {
  return clone(cooperationData.dialog)
}

function createDefaultCounselor(shared = {}) {
  const counselor = clone(cooperationData.dialog.counselor[0])

  Object.assign(counselor, shared)

  return counselor
}

function clone(data) {
  return JSON.parse(JSON.stringify(data))
}
