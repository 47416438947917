import { ReactiveComponent } from '~/commons/component/ReactiveComponent'
import { Component, Watch } from '~/commons/component/decorators'

import { createVNodeProxy } from '@rc/vue-vdom'

import * as QrCode from 'qrcode'

import './index.css'

export
function PatrolIndexViewProvider(BaseComponent) {
  @Component()
  class PatrolIndexView extends BaseComponent {
    showPatrolEntry() {
      // const sourceUrl = `${window.location.origin}/api/setPatrolUser?realname=${realname}&mobile${mobile}`
      const sourceUrl = `${window.location.origin}/home/focusCertification`

      QrCode.toDataURL(sourceUrl, { width: 400 }).then(qrcode => {
        const item = {}

        this.$msgbox(
          {
            message: (
              <el-form label-position="left" label-width="120px" model={ item }>
                <el-form-item label-width="0" style="text-align: center;">
                  <img src={ qrcode } width="200px" height="200px" />
                </el-form-item>
                <el-form-item label="入口链接">
                  <el-input value={ sourceUrl } style="width: 400px;" />
                </el-form-item>
              </el-form>
            ),

            // type: 'info',
            title: `巡检入口`,

            showConfirmButton: false,
            showCancelButton: false,
            customClass: 'patrol-entry-popup',
          }
        ).catch(() => {})

      }, () => {})
    }

    render() {
      const vnode = super.render()
      const root = createVNodeProxy(vnode)

      const { value: headerBtnView } = root.query({ slot: 'header-btn' }).next()

      headerBtnView?.children?.unshift?.(
        <el-button type="primary" size="small" onClick={ () => this.showPatrolEntry() } style="margin-right: 1em;">添加巡检入口</el-button>
      )

      return vnode
    }
  }


  return PatrolIndexView
}
